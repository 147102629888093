var page_loaded = 1;
global.page_loaded = page_loaded;
	
page = {
	load: function(){
	},
}


$(window).on('load', function(){
	page.load();
})